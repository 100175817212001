export const licenseZh = `
<h1>中国签证申请在线表格条款</h1>
<h2>关于个人信息的使用与保护</h2>
个人信息的采集，储存，传输和使用仅以签证申请为目的。<br>这些信息会传输给中国使馆和中国签证中心。<br>工作人员有可能通过电话邮件或者其他方式采集除电子申请表格之外的信息。<br>客户应当向我们提交完整和真实的信息，中国使馆从而可以对签证申请做出正确的决定。<br>我们对于个人信息存储至签证申请程序完成，之后会删除。我们不保证使馆和中国签证申请中心会保存申请人的个人信息。
<h2>取得和删除个人信息</h2>
您有权取得我们保存的您的个人信息。如果信息有误，您可以要求我们更正。按照法律要求，您可以要求我们在可能的条件下删除您的个人信息。
<h2>控制人</h2>
个人信息由Alla Visum i Sverige AB收集，邮件联系方式kontakt@allavisum.se，电话联系方式08-53332060。
<h2>监控</h2>
瑞典信息保护机关（DPA）是一个公共机构主要负责保护信息社会中的个人隐私安全。关于对于个人隐私侵犯的诉求可以向DPA提出。网址为<a href="https://www.datainspektionen.se/">www.datainspektionen.se</a>。<br>
<strong>我同意按照上面的条款存储和处理我的个人信息。</strong>
`
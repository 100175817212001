

function getArrayCard(title : string, properties : any) {

    let aryCard : any = {
        "type": "array",
        "x-decorator": "FormItem",
        "x-component": "ArrayCards",
        "x-component-props": {
            "title": title
        },
        "x-validator": [],
        "x-decorator-props": {},
        "required": true,
        "x-designable-id": "sluywee3lc5",
    
        "default": "{{[{}]}}",
        "items": {
            "type": "object",
            "x-designable-id": "qgyqk5j2mqo",
            "properties": {
                "hklfsisbcvb": {
                    "type": "void",
                    "x-component": "ArrayCards.Index",
                    "x-designable-id": "hklfsisbcvb",
                    "x-index": 0
                },
                "itft5ttgzw1": {
                    "type": "void",
                    "title": "Addition",
                    "x-component": "ArrayCards.Remove",
                    "x-designable-id": "itft5ttgzw1",
                    "x-index": 1
                },
                "ri0iopoc08d": {
                    "type": "void",
                    "title": "Addition",
                    "x-component": "ArrayCards.MoveDown",
                    "x-designable-id": "ri0iopoc08d",
                    "x-index": 2
                },
                "20zgkloww99": {
                    "type": "void",
                    "title": "Addition",
                    "x-component": "ArrayCards.MoveUp",
                    "x-designable-id": "20zgkloww99",
                    "x-index": 3
                }
            }
        },
        "properties": {
            "ssib6stfszt": {
                "type": "void",
                "title": "Addition",
                "x-component": "ArrayCards.Addition",
                "x-designable-id": "ssib6stfszt",
                "x-index": 0
            }
        },
        "x-reactions": {
            "dependencies": [
                "page2.work_experience",
                "page2.jobtype"
            ],
            "fulfill": {
                "schema": {
                    "x-visible": "{{$deps[0] != true && $deps[1] != '713002'}}"
                }
            }
        }
    }

    let index = 3;
    for(var k in properties) {
        aryCard.items.properties[k] = JSON.parse(JSON.stringify(properties[k]));
        index++;
        aryCard.items.properties[k]["x-index"] = index;
    }

    return aryCard;
}

export default getArrayCard;
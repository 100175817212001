import React, { useMemo, useState } from "react"
import { createForm, } from "@formily/core"
import {
  createSchemaField,
  Schema, FormProvider, FormConsumer,
} from "@formily/react"
import {
  Form,
  FormItem,
  DatePicker,
  Checkbox,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  Transfer,
  TreeSelect,
  Upload,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
  ArrayItems,
  FormStep,
  FormButtonGroup,
} from "@formily/antd"
import { Card, Slider, Rate, Button, Divider, Col, Row, ConfigProvider } from "antd"
import getArrayCard from '../arrayCard'
import { setValidateLanguage } from '@formily/core'
import { getLang, getLangText, loadLang } from "../lang"
import loadJson from "../loadJson"
import { useTranslation } from 'react-i18next';
import moment from "moment"
import axios from "axios"


const Text: React.FC<{
  value?: string
  content?: string
  mode?: "normal" | "h1" | "h2" | "h3" | "p"
}> = ({ value, mode, content, ...props }) => {
  const tagName = mode === "normal" || !mode ? "div" : mode
  return React.createElement(tagName, props, value || content)
}

const SchemaField = createSchemaField({
  components: {
    Space,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayCards,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    Text,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    Card,
    Slider,
    Rate,
    Divider,
    ArrayItems,
    FormStep,
  },
  scope: {
    birthday() {
      let f = window.sessionStorage.getItem('form')
      if (!f) {
        return;
      }

      let data = JSON.parse(f)
      if (!data.page0) {
        return;
      }
      console.log(data.page0.personinfo_birthday)
      if (moment().diff(data.page0.personinfo_birthday, 'years') < 14 || moment().diff(data.page0.personinfo_birthday, 'years') >= 70) {
        return false;
      }

      if ((data.page1.applyvisavalidity == '1|3' || data.page1.applyvisavalidity == '2|6')
        && data.page1.visa_category != 'F' && data.page1.visa_category != 'Z' && data.page1.visa_category != 'X1' && data.page1.visa_category != 'X2') {
          return false;
      }

      return true;
    },
  },
})

let total = 7;
let page = 0;
let values: any = {};
function append(properties: any, form:any) {
  let langText = getLangText();
  if (typeof properties != 'object') {
    return;
  }

  for (var k in properties) {
    if (properties[k].country) {
      properties[k]['enum'] = loadJson(require('../form/country.json'));
      if (!properties[k]['x-component-props']) {
        properties[k]['x-component-props'] = {};
      }
      Object.assign(properties[k]['x-component-props'], {
        "filterOption": "{{(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}}",
        "showSearch": true
      })
    }
    if (properties[k].city) {
      properties[k]['enum'] = loadJson(require('../form/city.json'));
      properties[k]['x-component-props'] = {
        "filterOption": "{{(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}}",
        "showSearch": true
      }
    }
    if (properties[k]['x-component'] == 'ArrayItems.Addition') {
      properties[k]["x-display"] = "visible";
    }
    if (properties[k]['x-decorator'] == 'FormItem') {
      properties[k]["x-pattern"] = "editable";
    }
    if (properties[k]['request-time']) {
      properties[k]["default"] = moment().format('yyyy-MM-DD');
    }
    if (properties[k]['finger-date']) {
      let request_time = form.getValuesIn('page6.request_time');
      if (!request_time) {
        request_time = moment().format('yyyy-MM-DD');
      }

      let startTime = moment(request_time).add(1, 'days');
      let times = [];
      for (let day = 1; day < 30; day++) {
        let date = startTime.add(1, 'days');
        if (date.weekday() !== 0 && date.weekday() !== 6 
          && !['2023-05-01','2023-05-02','2023-05-03','2023-05-18','2023-06-06','2023-06-22','2023-06-23',].includes(date.format('yyyy-MM-DD'))) {
          times.push({
            "value": date.format('yyyy-MM-DD'),
            "label": date.format('yyyy-MM-DD'),
          });
        }
      }
      properties[k]["enum"] = times;
    }

    append(properties[k], form);
    // properties[k].required = false;
  }
}

function loadSchemas(form:any): any {
  let langText = getLangText();
  setValidateLanguage(langText);

  let schemas: any[] = [];
  let titles = loadJson(require('../form/title.json'));

  let steps: any = {
    type: 'object',
    properties: {
      step: {
        type: 'void',
        'x-component': 'FormStep',
        'x-component-props': {
          formStep: '{{formStep}}',
        },
        properties: {
        }
      }
    }
  }

  for (let i = 0; i < total; i++) {
    let pageId = 'page' + i;
    let properties = loadJson(require('../form/' + pageId));

    if (i == 0) {
      properties['personcountryinfoother_countryname']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['personcountryinfoother_passport']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['personcountryinfoother_idno']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['countryname']["x-reactions"] = {
        "dependencies": ["page0.isHaveFormerNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
    }
    if (i == 2) {
      properties['work'] = getArrayCard('work experience', loadJson(require('../form/work.json')))
    }
    append(properties, form);

    let pageSchema: any = {
      "type": "object",
      "properties": {},
      // "x-designable-id": "evkl3lnd817"
    }
    pageSchema = {
      "type": "object",
      "x-validator": [],
      "name": pageId,
      "x-designable-id": "t5o2alosh0o",
      "x-index": 0,
      "properties": {}
    }
    pageSchema.properties[pageId] = {
      "type": "void",
      "x-component": "Card",
      "x-component-props": {
        "title": titles[i]
      },
      "name": pageId,
      "x-designable-id": "tv5ugu972g8",
      "x-index": 0,
      "properties": properties
    };

    let step : any = {
      type: 'void',
      'x-component': 'FormStep.StepPane',
      'x-component-props': {
        title: 'Step ' + (i+1),
      },
      properties: {},
    }
    step.properties[pageId] = pageSchema;
    console.log(JSON.stringify(pageSchema));
    // schemas.push(pageSchema);
    steps.properties.step.properties[pageId] = step;
  }
  console.log(steps)
  console.log(JSON.stringify(steps));

  let langAry = require('../form/lang/'+langText);
  loadLang(steps, langAry);
  return steps;
}

function saveValues(page: any, values: any) {
  window.sessionStorage.setItem('form', JSON.stringify(values));
  window.sessionStorage.setItem('page', page);
}

function needFinger() {
  let f = window.sessionStorage.getItem('form')
  if (!f) {
    return;
  }

  let data = JSON.parse(f)
  if (!data.page0) {
    return;
  }
  console.log(data.page0.personinfo_birthday)
  if (moment().diff(data.page0.personinfo_birthday, 'years') < 14 || moment().diff(data.page0.personinfo_birthday, 'years') >= 70) {
    return '0';
  }

  if ((data.page1.applyvisavalidity == '1|3' || data.page1.applyvisavalidity == '2|6')
    && ['L', 'M', 'Q2', 'G', 'C'].includes(data.page1.visa_category)) {
      return '0';
  }

  return '1';
}

export default () => {
  const form = createForm({ validateFirst: true })

  // load from local
  let f = window.sessionStorage.getItem('form')
  if (f) {
    form.setValues(JSON.parse(f))
    if (form.getValuesIn('page0.personinfo_birthday') && form.getValuesIn('page1.applyvisavalidity')) {
      form.setValuesIn('page6.need_finger', needFinger());
    }
  }
  let p = window.sessionStorage.getItem('page')
  if (p) {
    page = parseInt(p);
  }
  console.log('page', page)
  let schemas = loadSchemas(form);
  const [schema, setSchema] = useState(schemas)

  let lang = getLang()
  const formStep = FormStep.createFormStep(page)
  const { t } = useTranslation();

  return (
    <ConfigProvider locale={lang}>
      <Form form={form} wrapperCol={8}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

          <SchemaField schema={schema} scope={{ formStep }} />
          <FormConsumer>
            {() => (
              <FormButtonGroup>
                <Button
                    type="primary"
                  disabled={!formStep.allowBack}
                  onClick={() => {
                    formStep.back()
                    values = JSON.parse(JSON.stringify(form.values));
                    page--;
                    saveValues(page, values)
                    window.scrollTo(0, 0)
                    console.log(values)
                  }}
                >
                  {t('Previous Section')}
                </Button>
                <Button
                    type="primary"
                  disabled={!formStep.allowNext}
                  onClick={() => {
                    formStep.next()
                    values = JSON.parse(JSON.stringify(form.values));
                    page++;
                    saveValues(page, values)
                    window.scrollTo(0, 0)
                    console.log(values)
                    if (form.getValuesIn('page0.personinfo_maritalstatus')) {
                      form.setValuesIn('page4.personinfo_maritalstatus', form.getValuesIn('page0.personinfo_maritalstatus'));
                    }
                    if (form.getValuesIn('page0.personinfo_birthday') && form.getValuesIn('page1.applyvisavalidity')) {
                      form.setValuesIn('page6.need_finger', needFinger());
                    }
                  }}
                >
                  {t('Next Section')}
                </Button>
                <Button
                    type="primary"
                  disabled={formStep.allowNext}
                  onClick={() => {
                    values = JSON.parse(JSON.stringify(form.values));
                    saveValues(page, values)
                    formStep.submit(() => {
                      window.scrollTo(0, 0)
                      window.location.href='#/View'
                    })
                  }}
                >
                  {t('View')}
                </Button>
                <Button
                  type="primary"
                  hidden={!window.sessionStorage.getItem('form_id')}
                  onClick={() => {
                    values = JSON.parse(JSON.stringify(form.values));
                    console.log(values)
                    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                    axios.post('/wp-content/plugins/application/update2.php', {
                      data: JSON.stringify(form.values),
                      form_id: window.sessionStorage.getItem('form_id')
                    })
                      .then(function (response) {
                        console.log(response);
                        if (response.data.status == 0) {
                          alert('success');
                        } else {
                          alert('failed');
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  {t('Save')}
                </Button>
              </FormButtonGroup>
            )}
          </FormConsumer>
          {/* <SchemaField schema={schema}>
          </SchemaField>
          <Row>
            <Col span={24}>
              <Space>

                {page > 0 ?
                  <Button
                    type="primary"
                    onClick={() => {
                      form.validate().then(() => {
                        values = JSON.parse(JSON.stringify(form.values));
                        page--;
                        setSchema(schemas[page])
                        form.setValues(values)
                        saveValues(page, values)
                        window.scrollTo(0, 0)
                      })
                    }}
                  >
                    Prev Section
                  </Button>
                  : null}

                {page < total - 1 ?
                  <Button
                    type="primary"
                    onClick={() => {
                      form.validate().then(() => {
                        values = JSON.parse(JSON.stringify(form.values));
                        if (page < total - 1) {
                          page++;
                          setSchema(schemas[page])
                          form.setValues(page, values)
                          saveValues(page, values)
                          window.scrollTo(0, 0)
                        }
                      })
                    }}
                  >
                    Next Section
                  </Button>
                  : null}

                {page >= total - 1 ?
                  <Button
                    type="primary"
                    onClick={() => {
                      form.validate()
                      values = JSON.parse(JSON.stringify(form.values));
                      saveValues(page, values)
                      console.log(values)
                      window.location.href = '#/view';
                    }}
                  >
                    View
                  </Button>
                  : null}
              </Space>
            </Col>
          </Row> */}
        </Space>
      </Form>
    </ConfigProvider>
  )
}

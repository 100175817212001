import zh from 'antd/lib/locale/zh_CN';
import en from 'antd/lib/locale/en_US';
import sv from 'antd/lib/locale/sv_SE';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

function loadLang(data:any, langAry:any) {
    if (typeof data != 'object') {
        return;
    }

    if (Array.isArray(data)) {
        for(let i = 0; i < data.length; i++) {
            loadLang(data[i], langAry);
        }
    } else {
        for(let k in data) {
            if (k == 'label' || k == 'title' || k == 'content' || k == 'addonAfter') {
                if (data[k].trim() == '') {
                    continue;
                }
                if (!langAry[data[k].trim()]) {
                    continue;
                }
                
                data[k] = langAry[data[k].trim()];
                continue;
            }

            loadLang(data[k], langAry);
        }
    }
}

function i18lang(lng:string) {
    console.log('i18', lng)
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // the translations
            // (tip move them in a JSON file and import them)
            resources: {
                en: {
                    translation: require('./form/lang/en.json')
                },
                zh: {
                    translation: require('./form/lang/zh.json')
                },
                sv: {
                    translation: require('./form/lang/sv.json')
                }
            },
            lng: lng,
            fallbackLng: 'en',

            interpolation: {
                escapeValue: false
            }
        });
}

function getLang() {
    let lang = window.sessionStorage.getItem('lang');
    if (!lang) {
        lang = 'en';
    }
    i18lang(lang)

    if (lang == 'zh') {
        return zh;
    } else if (lang == 'sv') {
        return sv;
    }

    return en;
}

function getLangText() {
    let lang = window.sessionStorage.getItem('lang');
    if (!lang) {
        lang = 'en';
    }

    if (lang == 'zh') {
        return 'zh';
    } else if (lang == 'sv') {
        return 'sv';
    }

    return 'en';
}

export { getLang, getLangText, loadLang };
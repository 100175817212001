export const licenseEn = `
<div><b>Terms for online visa application</b></div>
<div></div>
<div><b>Collection of personal data</b></div>
<div></div>
<div>Personal data is collected, stored, transferred and used by us to handle your visa application.</div>
<div>The information will also be transferred to the Embassy of China and to their visa office.</div>
<div>Extra information besides this electronic form may be obtained from you by phone, e-mail or documents that is provided to us.</div>
<div></div>
<div>Providing complete and truthful information is a prerequisite for the application to be handled and for fair decisions to be taken.</div>
<div></div>
<div>The information in this visa application will be stored by us until we finish the assignment and then it will be deleted. The embassy will, however, archive all the data in their own registry. Other personal information such as name, address, social security number, telephone number, e-mail address, customer history etc. will be stored until further notice in our customer register.</div>
<div></div>
<div><b>Access and delete personal data</b></div>
<div></div>
<div>You are entitled to access the information we store about you. You may request us to correct it if any information is incorrect, and you also have the right to request the data to be deleted in cases where it is possible under the law.</div>
<div></div>
<div><b>Controller</b></div>
<div></div>
<div>The personal data controller is Alla Visum i Sverige AB. Email address <a href="mailto:kontakt@allavisum.se" target="_blank" rel="noopener">kontakt@allavisum.se</a>, phone number 08-53332060.</div>
<div></div>
<div><b>Inspection</b></div>
<div></div>
<div>The Swedish Data Protection Authority&nbsp;(DPA) is a public authority. Their task is to protect the individual's privacy in the information society.&nbsp;Complaints regarding the handling of personal data can be reported to them. Website:<a href="http://www.datainspektionen.se/" target="_blank" rel="noopener">www.datainspektionen.se</a></div>
<div></div>
<div></div>
<div></div>
<div>By accepting the terms above, you can proceed to the visa application.</div>
<div></div>
<div>[ ] I consent to the handling and storage of my personal data as mentioned above</div>
`
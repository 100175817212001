import React, { Component } from 'react'
import { useTranslation } from 'react-i18next';
import { getLang } from '../lang';

export default () => {
    getLang();
    const { t } = useTranslation();

    return (
        <div className='section'>
            <p>{t('Your application has been successfully submitted, please go to the instruction page to continue.')}</p>
            <p><a href="https://www.allavisum.se/kina">https://www.allavisum.se/kina</a></p>
            <p><a href="https://www.mittensrike.se/kina">https://www.mittensrike.se/kina</a></p>
        </div>
    )
}

export const licenseSv = `
<h1>Villkor för visumansökan till Kina</h1>
<h2>Insamling av personuppgifter</h2>
Personuppgifter samlas in, lagras, överföras och användas av oss i syfte att hantera din visumansökan.<br>Informationen kommer även överföras till Kinas ambassad samt till deras visumkontor.<br>Ytterligare information utöver detta elektroniska formulär kan komma att hämtas in från er via telefon, e-post eller dokument som lämnas till oss.<br>Att uppge kompletta och sanningsenliga uppgifter är en förutsättning för att visumansökan ska kunna handläggas och för att ett rättvist beslut ska kunna tas.<br>Uppgifterna i denna visumansökan kommer lagras av oss tills vi slutfört uppdraget och därefter raderas. Ambassaden kommer dock arkivera alla uppgifter i sina egna register. Övriga personuppgifter som namn, adress, personnummer, telefonnummer, e-postadress, kundhistorik etc kommer sparas tills vidare i vårt kundregister.
<h2>Radera personuppgifter</h2>
Du har som registrerad rätt att få ta del av uppgifterna vi lagrat om dig. Du har rätt att begära rättelse om några uppgifter är fel, och du har dessutom rätt att begära att få uppgifterna raderade i de fall det är möjligt enligt lagen.
<h2>Personuppgiftsansvarig</h2>
Personuppgiftsansvarig är Alla Visum i Sverige AB. E-postadress&nbsp;kontakt@allavisum.se, telefonnummer&nbsp;08-53332060.
<h2>Kontrollmyndighet</h2>
Datainspektionen är den myndighet som genom sin tillsynsverksamhet ska bidra till att behandlingen av personuppgifter inte leder till otillbörliga intrång i enskilda individers personliga integritet.&nbsp;Klagomål gällande bristande hantering av personuppgifter kan anmälas till dem. Webbplats:&nbsp;<a href="https://www.datainspektionen.se/">www.datainspektionen.se</a><br>Genom att godkänna hanteringen av personuppgifter kan du gå vidare till visumansökan.<br><strong>Jag samtycker till hantering och lagring av mina personuppgifter enligt ovan.</strong>
`
import { Button, Col, Row, Space } from 'antd';
import React, { Component, useState } from 'react'
import { licenseEn } from '../html/license/en.html';
import { licenseSv } from '../html/license/sv.html';
import { licenseZh } from '../html/license/zh.html';
import { getLang, getLangText } from '../lang';

function getHtml() {
    let langText = getLangText();
    console.log('get', langText);
    if (langText == 'sv') {
        return licenseSv;
    }
    if (langText == 'zh') {
        return licenseZh;
    }
    return licenseEn;
}

let agrees = {
    'sv': 'Godkänn',
    'en': 'Agree',
    'zh': '同意',
}

export default () => {
    const [html, setHtml] = useState({ __html: getHtml() })
    let agree = agrees[getLangText()];

    return (
        <div className='license'>
            <div dangerouslySetInnerHTML={html}></div>
            <p> </p>
            <Row justify="center" align="middle">
                <Col>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                window.location.href = '#/app';
                            }}
                        >{agree}</Button>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}
export const homeEn = `
<div class="main2">
    <div class="box">
        <div class="cnt langs">
            <table width="500" border="0">
                <tbody>
                    <tr>
                        <td colspan="3"><strong>Select Language</strong></td>
                    </tr>
                    <tr>
                        <td><a><img src="./app/static/images/sv.png" lang="sv" width="96" height="64"></a></td>
                        <td><a><img src="./app/static/images/zh.png" lang="zh" width="96" height="64"></a></td>
                        <td><a><img src="./app/static/images/en.png" lang="en" width="96" height="64"></a></td>
                    </tr>
                    <tr>
                        <td>Svenska</td>
                        <td>中文</td>
                        <td>English</td>
                    </tr>
                </tbody>
            </table>
            <p></p>
            <h1>Chinese Visa Application Form</h1>
            <p>Please fill in your Chinese Visa Application Form online and submit it online. Then you can post or leave
                in person all the required documents (except the visa application form)to us.</p>
            <p><a href="#/license" class="btn btn1">Start a new application form</a></p>
            <p><a href="#/continue" class="btn btn1">Continue a saved application form</a></p>
        </div>
        <div class="btm"></div>
    </div>
</div>
`
export const homeZh = `
<div class="main2">
    <div class="box">
        <div class="cnt langs">
            <table width="500" border="0">
                <tbody>
                    <tr>
                        <td colspan="3"><strong>Select Language</strong></td>
                    </tr>
                    <tr>
                        <td><a><img src="./app/static/images/sv.png" lang="sv" width="96" height="64"></a></td>
                        <td><a><img src="./app/static/images/zh.png" lang="zh" width="96" height="64"></a></td>
                        <td><a><img src="./app/static/images/en.png" lang="en" width="96" height="64"></a></td>
                    </tr>
                    <tr>
                        <td>Svenska</td>
                        <td>中文</td>
                        <td>English</td>
                    </tr>
                </tbody>
            </table>
            <p></p>
            <h1>中国签证申请表格</h1>
            <p>在此填写中国签证申请表格，网上提交。之后请您邮寄或者递交护照和其他签证申请材料。</p>
            <p><a href="#/license" class="btn btn1">填写申请表格</a></p>
            <p><a href="#/continue" class="btn btn1">继续填写保存的表格</a></p>
        </div>
        <div class="btm"></div>
    </div>
</div>
`
import React, { useMemo, useState } from "react"
import { createForm, } from "@formily/core"
import {
  createSchemaField,
  Schema,
} from "@formily/react"
import {
  Form,
  FormItem,
  DatePicker,
  Checkbox,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  Transfer,
  TreeSelect,
  Upload,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
  ArrayItems,
} from "@formily/antd"
import { Card, Slider, Rate, Button, Divider, Col, Row, ConfigProvider } from "antd"
import getArrayCard from '../arrayCard'
import { setValidateLanguage } from '@formily/core'
import { getLang, getLangText, loadLang } from "../lang"
import { useTranslation } from "react-i18next"
import axios from 'axios'
import moment from 'moment'

const Text: React.FC<{
  value?: string
  content?: string
  mode?: "normal" | "h1" | "h2" | "h3" | "p"
}> = ({ value, mode, content, ...props }) => {
  const tagName = mode === "normal" || !mode ? "div" : mode
  return React.createElement(tagName, props, value || content)
}

const SchemaField = createSchemaField({
  components: {
    Space,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayCards,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    Text,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    Card,
    Slider,
    Rate,
    Divider,
    ArrayItems,
  },
  scope: {
    birthday(day: string) {
      let f = window.sessionStorage.getItem('form')
      if (!f) {
        return;
      }

      let data = JSON.parse(f)
      if (!data.page0) {
        return;
      }
      console.log(data.page0.personinfo_birthday)
      return moment().diff(data.page0.personinfo_birthday, 'years') >=14 && moment().diff(data.page0.personinfo_birthday, 'years') < 70
    },
  },
})

let total = 7;
let values: any = {};
function append(properties: any) {
  let langText = getLangText();
  if (typeof properties != 'object') {
    return;
  }

  for (var k in properties) {
    if (properties[k].country) {
      properties[k]['enum'] = require('../form//country.json');
      if (!properties[k]['x-component-props']) {
        properties[k]['x-component-props'] = {};
      }
      Object.assign(properties[k]['x-component-props'], {
        "filterOption": "{{(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}}",
        "showSearch": true
      })
    }
    if (properties[k].city) {
      properties[k]['enum'] = require('../form//city.json');
      properties[k]['x-component-props'] = {
        "filterOption": "{{(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}}",
        "showSearch": true
      }
    }
    if (properties[k]['x-component'] == 'ArrayItems.Addition') {
      properties[k]["x-display"] = "none";
    }
    if (properties[k]['x-decorator'] == 'FormItem') {
      properties[k]["x-pattern"] = "readPretty";
    }

    append(properties[k]);
    // properties[k].required = false;
  }
}
function loadSchemas(): any {
  let langText = getLangText();
  setValidateLanguage(langText);

  let titles = require('../form//title');
  let pageSchema: any = {
    "type": "object",
    "properties": {},
    // "x-designable-id": "evkl3lnd817"
  }

  for (let i = 0; i < total; i++) {
    let pageId = 'page' + i;
    let properties = require('../form/' + pageId);

    if (i == 0) {
      properties['personcountryinfoother_countryname']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['personcountryinfoother_passport']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['personcountryinfoother_idno']["x-reactions"] = {
        "dependencies": ["page0.isHaveOtherNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
      properties['countryname']["x-reactions"] = {
        "dependencies": ["page0.isHaveFormerNationality"],
        "fulfill": {
          "schema": {
            "x-visible": "{{$deps[0] === 1}}"
          }
        }
      }
    }
    if (i == 2) {
      properties['work'] = getArrayCard('work experience', require('../form/work.json'))
    }
    append(properties);

    pageSchema.properties[pageId] = {
      "type": "object",
      "x-validator": [],
      "name": pageId,
      // "x-designable-id": "t5o2alosh0o",
      "x-index": i,
      "properties": {}
    }
    pageSchema.properties[pageId].properties[pageId] = {
      "type": "void",
      "x-component": "Card",
      "x-component-props": {
        "title": titles[i]
      },
      "name": pageId,
      // "x-designable-id": "tv5ugu972g8",
      "x-index": 0,
      "properties": properties
    };
    console.log(JSON.stringify(pageSchema));
  }

  let langAry = require('../form/lang/' + langText);
  loadLang(pageSchema, langAry);
  return pageSchema;
}

function needFinger() {
  let f = window.sessionStorage.getItem('form')
  if (!f) {
    return;
  }

  let data = JSON.parse(f)
  if (!data.page0) {
    return;
  }
  console.log(data.page0.personinfo_birthday)
  if (moment().diff(data.page0.personinfo_birthday, 'years') < 14 || moment().diff(data.page0.personinfo_birthday, 'years') >= 70) {
    return '0';
  }

  if ((data.page1.applyvisavalidity == '1|3' || data.page1.applyvisavalidity == '2|6')
      && ['L', 'M', 'Q2', 'G', 'C'].includes(data.page1.visa_category)) {
    return '0';
  }

  return '1';
}

export default () => {
  const form = createForm({ validateFirst: true })

  // load from local
  let f = window.sessionStorage.getItem('form')
  if (f) {
    form.setValues(JSON.parse(f))
    if (form.getValuesIn('page0.personinfo_birthday') && form.getValuesIn('page1.applyvisavalidity')) {
      form.setValuesIn('page6.need_finger', needFinger());
    }
  }

  let lang = getLang()
  let schemas = loadSchemas();
  const { t } = useTranslation();

  return (
    <ConfigProvider locale={lang}>
      <Form form={form} wrapperCol={8}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <SchemaField schema={schemas}>
          </SchemaField>
          <Row>
            <Col span={24}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.href = '#/app';
                  }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  type="primary"
                  disabled={!!window.sessionStorage.getItem('form_id')}
                  onClick={() => {
                    values = JSON.parse(JSON.stringify(form.values));
                    console.log(values)
                    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                    axios.post('https://application.allavisum.se/wp-content/plugins/application/submit2.php', {
                      data: JSON.stringify(form.values)
                    })
                      .then(function (response) {
                        console.log(response);
                        if (response.data.status == 0) {
                          window.sessionStorage.clear();
                          window.location.href = '#/success';
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  {t('Submit')}
                </Button>
                <Button
                  type="primary"
                  disabled={!window.sessionStorage.getItem('form_id')}
                  onClick={() => {
                    values = JSON.parse(JSON.stringify(form.values));
                    console.log(values)
                    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                    axios.post('/wp-content/plugins/application/update2.php', {
                      data: JSON.stringify(form.values),
                      form_id: window.sessionStorage.getItem('form_id')
                    })
                      .then(function (response) {
                        console.log(response);
                        if (response.data.status == 0) {
                          window.location.href = '#/success';
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  {t('Save')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </Form>
    </ConfigProvider>
  )
}

import React, { Component, useState } from 'react'
import { Link } from "react-router-dom";
import { homeEn } from "../html/home/en.html";
import { homeSv } from "../html/home/sv.html";
import { homeZh } from "../html/home/zh.html";
import {getLang, getLangText} from '../lang';

function getHtml() {
    let langText = getLangText();
    console.log('get', langText);
    if (langText == 'sv') {
        return homeSv;
    }
    if (langText == 'zh') {
        return homeZh;
    }
    return homeEn;
}

export default () => {
    const [html, setHtml] = useState({ __html: getHtml() })

    let handleClick = (event: any) => {
        // ?️ use event.preventDefault() if you want to
        // prevent navigation
        // event.preventDefault();

        // ?️ refers to the link element
        let lang = event.target.getAttribute('lang');
        if (!lang) {
            return;
        }
        
        window.sessionStorage.setItem('lang', lang);
        console.log('set lang', lang);
        setHtml({ __html: getHtml() });
    };

    return (
        <div onClick={handleClick} dangerouslySetInnerHTML={html}>
        </div>
    )
}